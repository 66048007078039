import React from "react";
import '../index.css';
import CaruselFenster from "./PictureCarusel/CaruselFenster";


const Fenster = () => {

    return (
        <div className="row maincontent">
            <div className="col s12 m8">
                <CaruselFenster/>
            </div>
            <div className="col s12 m4">
                <p className="flow-text fontSize">Als Wiener Tischlerei, welche seit nunmehr über 30 Jahren besteht,
                    sind wir mittlerweile einer der wenigen CE – gekennzeichneten produzierenden Betriebe von Fenstern
                    und Türen mit einer eigenen Werkstatt in Wien.
                    Durch unsere Jahrzehnte lange Erfahrung und dem täglichen Umgang im Gebrauch, der Handhabung, der
                    Sanierung und Reparatur sowie der eigenen Produktion von Fenstern und Türen sowie von
                    Fensternachbauten bzw. Teilnachbauten sind wir absolute Spezialisten auf dem Gebiet bei allen
                    Arbeiten rund um Kastenfenster.
                </p>
                <p className="flow-text fontSize">Kastenfenster:</p>

                <p className="flow-text fontSize">
                    Bei dem Thema der Sanierung von Kastenfenstern, speziell in Schutzzonen bzw. bei Denkmalgeschützten
                    Häusern, gehört für uns natürlich auch der intensive Austausch und die äußerst positive
                    Zusammenarbeit mit dem Bundesdenkmalamt dazu.
                </p>

                <p className="flow-text fontSize">Folgende Arbeiten bieten wir auszugsweise bei Kastenfenstern an:</p>
                <ul className="fontSize">
                    <li>- Instandsetzungsarbeiten an Außenfenstern und Innenfenstern</li>
                    <li>- Dichten von Innenfenstern</li>
                    <li>- Beschlagtechnisches herrichten und Instandsetzungsarbeiten von Kastenfenstern</li>
                    <li>- Schall- und Wärmetechnische Aufwertung von Kastenfenstern</li>
                    <li>- Auswechslung vermorschter Fensterteile</li>
                    <li>- Neuanfertigung erforderlicher Fensterflügel, ….</li>
                </ul>
                <p className="flow-text fontSize">Isolierglasfenster:</p>
                <p className="flow-text fontSize">Auf dem Sektor von Isolierglasfenstern und Türen bieten wir folgende Leistungen an: </p>
                <ul className="flow-text fontSize">
                    <li>- Neue Isolierglasfenster aus Holz, Holz-Alu oder Kunststoff</li>
                    <li>- Servicieren der Fenster, einstellen der Fenster, ölen der Beschläge</li>
                    <li>- Sonnenschutz neu</li>
                    <li>- Bestehenden Sonnenschutz (Jalousien) servicieren</li>
                    <li>- Umbau der Fenster auf WKII (Widerstandsklasse 2)e</li>
                    <li>- Neue Fensterbretter</li>
                    <li>- Reparaturarbeiten nach Einbruch</li>
                    <li>- Sanierungsarbeiten nach Brandschäden</li>
                    <li>- Dichtungs- und Beschlägetausch</li>
                </ul>
            </div>
        </div>
    );
};

export default Fenster;