import React from "react";
import picture1 from "../../Pictures/Fotos-190827/Fotos 190827-Web/190827__DSC2197.jpg";
import picture2 from "../../Pictures/Fotos-190827/Fotos 190827-Web/190827__DSC2244.jpg";
import picture3 from "../../Pictures/Fotos-190827/Fotos 190827-Web/190827__DSC2111.jpg";
import picture4 from "../../Pictures/Fotos-190827/Fotos 190827-Web/190827__DSC2272.jpg";
import picture5 from "../../Pictures/Fotos-190827/Fotos 190827-Web/190827__DSC2353.jpg";
import picture6 from "../../Pictures/20160916_113816.jpg";
import picture7 from "../../Pictures/20160225_152424.jpg";
import picture8 from "../../Pictures/Garderobewand.jpg";
import picture9 from "../../Pictures/820c76826d77f610af5b515cd93f9a4ed94e94cfc0faddda85eaf48e05655fbd.jpg";
import picture10 from "../../Pictures/P3080352.JPG";
import picture11 from "../../Pictures/P1060407.JPG";
import picture12 from "../../Pictures/P1060404.JPG";
import picture13 from "../../Pictures/IMG-20180726-WA0001.jpg";
import picture14 from "../../Pictures/Fensterverbau4.png";

import LazyLoad from 'react-lazyload';
import '../../index.css';
import Carousel from "react-multi-carousel";


const CaruselMoebeltischlerei = () => {
    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            infinite={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
            <LazyLoad>
                <img className="carimg" src={picture1} alt="alt"/>
            </LazyLoad>
            <img className="carimg" src={picture2} alt="alt"/>
            <img className="carimg" src={picture3} alt="alt"/>
            <img className="carimg" src={picture4} alt="alt"/>
            <img className="carimg" src={picture5} alt="alt"/>
            <img className="carimg" src={picture6} alt="alt"/>
            <img className="carimg" src={picture7} alt="alt"/>
            <img className="carimg" src={picture8} alt="alt"/>
            <img className="carimg" src={picture9} alt="alt"/>
            <img className="carimg" src={picture10} alt="alt"/>
            <img className="carimg" src={picture11} alt="alt"/>
            <img className="carimg" src={picture12} alt="alt"/>
            <img className="carimg" src={picture13} alt="alt"/>
            <img className="carimg" src={picture14} alt="alt"/>
        </Carousel>
    );
};

export default CaruselMoebeltischlerei;