import React from "react";
import '../index.css';
import CaruselHandarbeit from "./PictureCarusel/CaruselHandarbeit";


const Handarbeit = () => {
    return (
        <div className="row maincontent">
            <div className="col s12 m8">
                <CaruselHandarbeit/>
            </div>
            <div className="col s12 m4">
                <p className="flow-text fontSize">Auch wenn sehr viele unserer Arbeitsschritte bereits von Maschinen unterstützt
                    werden, kommen wir ohne unserer Hände Arbeit nicht aus.</p>
                <p className="flow-text fontSize">Dies macht uns zu Handwerkern!</p>
                <p className="flow-text fontSize">Handarbeit ist beim Zeichnen der Pläne am Computer oder beim Zeichnen von
                    Details in der Werkstatt genauso gefragt wie bei diversen Arbeiten an großen und kleinen
                    Maschinen.</p>
            </div>
        </div>
    );
};

export default Handarbeit;