import React from "react";
import "../index.css";

const Impressum = () => {
  return (
    <div className="maincontent container">
      <h3>Impressum</h3>
      <p>
        <a href="https://firmen.wko.at/ludwig-weichinger-hieden/wien/?firmaid=93eb9ca1-c7c0-47b4-b68b-b03208a5a828&standortid=1&standortname=wien%20%28bundesland%29&suchbegriff=weichinger">
          Zum Impressum lt. ECG.
        </a>
      </p>
      <div className={"divider"}></div>
      <h5>Ersteller der Webseite</h5>
      <ul className={"float-text fontSize"}>
        <li>Laurids Hieden</li>
        <li>laurids.hieden@aon.at</li>
      </ul>
      <div className={"divider"}></div>
      <span className={"float-text fontSize"}>
        Alle Rechte, Inhalte oder Bilder gehören Ludwig Weichinger-Hieden. Text- und Bildkopien, sowie Zitate erfordern
        die Zustimmung des Webseiteninhabers.
      </span>
      <p className={"float-text fontSize"}>Fotonachweis Ludwig Weichinger-Hieden/Kalb</p>
      <div className={"divider"}></div>
      <h5>Datenschutz</h5>
      <p className={"fontSize"}>Es werden keine Daten gespeichert oder weitergegeben.</p>
    </div>
  );
};

export default Impressum;
