import React from "react";
import picture1 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2449.jpg";
import picture2 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2454.jpg";
import picture3 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2468.jpg";
import picture4 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2474.jpg";
import picture5 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2475.jpg";
import picture6 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2486.jpg";
import picture7 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2492.jpg";
import picture8 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2497.jpg";
import picture9 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2498.jpg";
import picture10 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2501.jpg";
import picture11 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2519.jpg";
import picture12 from "../../Pictures/20191122_174854.jpg";
import picture13 from "../../Pictures/20191122_174927.jpg";

import LazyLoad from 'react-lazyload';
import '../../index.css';
import Carousel from "react-multi-carousel";

const CaruselFenster = () => {
    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return(
        <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            infinite={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
            <LazyLoad>
                <img className="carimg" src={picture1} alt="alt"/>
            </LazyLoad>

            <img className="carimg" src={picture2} alt="alt"/>
                <img className="carimg" src={picture3} alt="alt"/>
                <img className="carimg" src={picture4} alt="alt"/>
                <img className="carimg" src={picture5} alt="alt"/>
                <img className="carimg" src={picture6} alt="alt"/>
                <img className="carimg" src={picture7} alt="alt"/>
                <img className="carimg" src={picture8} alt="alt"/>
                <img className="carimg" src={picture9} alt="alt"/>
                <img className="carimg" src={picture10} alt="alt"/>
                <img className="carimg" src={picture11} alt="alt"/>
                <img className="carimg" src={picture12} alt="alt"/>
                <img className="carimg" src={picture13} alt="alt"/>
        </Carousel>

    );
};

export default CaruselFenster;