import React, {Component} from "react";
import '../index.css';

class Firmengeschichte extends Component {
    render() {
        return (
            <div className="container maincontent">
                <h2 className="heading">Firmengeschichte</h2>
                <ul className="fontSize">
                    <li>1986: Gesellenprüfung an der HTL Mödling</li>
                    <li> 1990: Meisterprüfung in St. Pölten</li>
                    <li>1990: Beginn der Selbständigkeit als Bau- und Möbeltischler in 1150 Wien, Selzergasse 18</li>
                    <li>2008: Gerichtssachverständigenprüfung über Teile der Fachgruppe 39, Holzbearbeitung</li>
                    <li>2011: Erweiterungsprüfung bei Gericht für die Fachgruppe 39 – Holzbearbeitung</li>
                    <li>2019: Übernahme der Tischlerei Burgstaller in 1020 Wien</li>
                    <li>2020: Neue Adresse von Werkstatt und Büro ausschließlich nur mehr: 1020 Wien, Böcklinstraße 26
                    </li>
                </ul>
                <p className="flow-text fontSize">Standardmäßig sind bei uns Planungen und Visualisierungen einzelner
                    Möbel sowie
                    Fenstereinzelkonstruktionen bis zu ganzen Projekten über Zeichenprogramme am PC.
                    Das Thema der Nachhaltigkeit bekommt bei uns in der Firma immer mehr Gewicht, wird immer wichtiger!
                    Dazu gehört die Umstellung der Firmenautos auf Elektromobilität
                    Immer mehr Reparatur- und Ergänzungsarbeiten, statt Neuproduktionen
                    Achtsamer Umgang mit Materialien und entsprechender Materialauswahl bei Neuproduktionen.
                </p>
            </div>
        );
    }
}

export default Firmengeschichte;