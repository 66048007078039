import React from "react";
import '../index.css';
import CaruselBauTischler from "./PictureCarusel/CaruselBauTischler";


const BauTischler = () => {

    return (
        <div className="row maincontent">
            <div className="col s12 m8">
                <CaruselBauTischler/>
            </div>
            <div className="col s12 m4">
                <ul className="fontSize">
                   <li className="list">Fenster: dazu zählen Fensterstöcke und Fensterflügel</li>
                    <li>Produktion</li>
                    <li>Reparaturen</li>
                    <li>Umbauten</li>
                    <li>Ergänzungen</li>
                </ul>
                <ul className="fontSize">
                    <li className="list">Türen: dazu zählen Türstöcke und Türblätter sowie Holzzargen</li>
                    <li>Produktion</li>
                    <li>Reparaturen</li>
                    <li>Umbauten</li>
                    <li>Ergänzungen</li>
                </ul>
                <ul className="fontSize">
                    <li className="list">Böden: Neuverlegung von Böden aller Art wie Fertigparkettböden </li>
                    <li>Reparaturen</li>
                    <li>Auswechslungen nach Brand- und Wasserschäden</li>
                </ul>
                <ul className="fontSize">
                    <li className="list">Terrassenbau aus Holz:</li>
                    <li>Neuverlegung</li>
                    <li>Auswechslungen</li>
                    <li>Reparaturen</li>
                </ul>
                <ul className="fontSize">
                    <li className="list">Handläufe: Neuherstellung</li>
                    <li>2.Handlauf in 2.Höhe</li>
                    <li>Ergänzungen</li>
                    <li>Umbauten</li>
                    <li>Reparaturen</li>
                </ul>
            </div>
        </div>
    );
};

export default BauTischler;