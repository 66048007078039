import React from "react";
import '../index.css';
import CaruselAlteHandwerk from "./PictureCarusel/CaruselAlteHandwerk";

const AlteHandwerks = () => {
    return (
        <div className="row maincontent">
            <div className="col s12 m12 l8">
                <CaruselAlteHandwerk/>
            </div>
            <div className="col s12 m12 l4">
                <p className="flow-text fontSize" >Diese Bilder vermitteln ein wenig den Umgang mit alten Möbeln, welche zu neuem Leben erweckt werden, wo fehlende Teile liebevoll, mit viel Fachwissen und Handwerkskunst ergänzt werden.</p>
                <p className="flow-text fontSize">Bei diesen Arbeiten werden noch Holznägel, Knochenleime, Alkannawurzeln, Adernkratzer, heisser Vogelsand, …. eingesetzt und verarbeitet.</p>
                <p className="flow-text fontSize">Fehlende Furnierteile werden ergänzt, lose Furnier neu verleimt.</p>
                <p className="flow-text fontSize">Alte Laufrahmen von Laden werden aufgedoppelt, ….</p>
                <p className="flow-text fontSize">Als Krönung werden dann die Oberflächen, Originalgetreu, mit einer Schelllackpolitur in schweißtreibender Handarbeit veredelt.</p>
                <p className="flow-text fontSize">Ruhe, großes Fachwissen und Liebe zum Detail zeichnen jene Mitarbeiter aus, welche sich auf diesem Spezialgebiet der Tischlerei bisher profiliert haben und sich mit dieser Materie ständig weiter auseinandersetzen!</p>
            </div>
        </div>
    );
};

export default AlteHandwerks;