import React from "react";
import karte from "../Pictures/StandortGoogleMaps.PNG";
import "../index.css";

const Contact = () => {
  return (
    <div className="maincontent">
      <div className="row flow-text fontSize">
        <div className="col s12 m12 l6">
          <img src={karte} alt="Google Maps" width="100%" />
        </div>
        <div className="col s12 m12 l6">
          <h3>Bau-und Möbeltischlerei</h3>
          <ul>
            <li className={"paddingBottom10"}>Ludwig Weichinger-Hieden</li>
            <li className={"paddingBottom10"}>Böcklinstraße 26</li>
            <li className={"paddingBottom10"}>1020 Wien</li>
            <li className={"paddingBottom10"}>ATU 11900200</li>
            <li className={"paddingBottom10"}>Telefon: 0676 411 55 76</li>
            <li className={"paddingBottom10"}>
              <a href={"http://www.tischlerei-weichinger.at"}>www.tischlerei-weichinger.at</a>
            </li>
            <li className={"paddingBottom10"}>
              <a className="mailtoui" href="mailto:office@tischlerei-weichinger.at">
                office@tischlerei-weichinger.at
              </a>
            </li>
          </ul>
        </div>
        <div className="col s12 m12 l6">
          <h3 className="col s12 m5 l5" style={{ fontSize: 20 }}>
            In Partnerschaft mit:
          </h3>
          <a
            className="col s12 m7 l7"
            href="https://sander-doll.com/startseite"
            title="Handwerkersoftware für Industrie, Handel & Handwerk"
          >
            <img
              src="https://sander-doll.com/files/erfolgsgeschichten/sanderunddoll_partnersiegel.jpg"
              alt="Sander & Doll"
              width={110}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
