import React from "react";
import picture1 from "../../Pictures/20181025_150431.jpg";
import picture2 from "../../Pictures/20181025_150645.jpg";
import picture3 from "../../Pictures/20200410_143919.jpg";

import LazyLoad from 'react-lazyload';
import '../../index.css';
import Carousel from "react-multi-carousel";


const CaruselBauTischler = () => {


    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            infinite={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
            <LazyLoad>
                <img className="carimg" src={picture1} alt="alt"/>
            </LazyLoad>
            <img className="carimg " src={picture2} alt="alt"/>
            <img className="carimg" src={picture3} alt="alt"/>
        </Carousel>
    );
};

export default CaruselBauTischler;