import React from "react";
import "../index.css";

const MoebelPlaner = () => {
  return (
    <div className="maincontent">
      <div className="planer">
        <iframe
          title="furniture-builder"
          src="https://moebelplaner.tischlerei-weichinger.at"
          height="666px"
          width="950px"
          border="0"
        ></iframe>
      </div>
    </div>
  );
};

export default MoebelPlaner;
