import React from "react";
import picture1 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2544.jpg";
import picture2 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2600.jpg";
import picture3 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2565.jpg";
import picture4 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2474.jpg";
import picture5 from "../../Pictures/Fotos-190827/Fotos 190827-Web/190827__DSC2080.jpg";
import picture6 from "../../Pictures/Fotos-190827/Fotos 190827-Web/190827__DSC2097.jpg";
import picture7 from "../../Pictures/Fotos-190827/Fotos 190827-Web/190827__DSC2130.jpg";
import picture8 from "../../Pictures/Fotos-190827/Fotos 190827-Web/190827__DSC2141.jpg";
import picture9 from "../../Pictures/Fotos-190827/Fotos 190827-Web/190827__DSC2150.jpg";
import picture10 from "../../Pictures/Fotos-190827/Fotos 190827-Web/190827__DSC2172.jpg";
import picture11 from "../../Pictures/Fotos-190827/Fotos 190827-Web/190827__DSC2267.jpg";

import '../../index.css';
import Carousel from "react-multi-carousel";
import LazyLoad from 'react-lazyload';


const CaruselHandarbeit = () => {

    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1400},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1400, min: 415},
            items: 1 ,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 415, min: 0},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            infinite={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
            <LazyLoad>
            <img className="carimg" src={picture1} alt="alt"/>
            </LazyLoad>
            <img className="carimg" src={picture2} alt="alt"/>
            <img className="carimg" src={picture3} alt="alt"/>
            <img className="carimg" src={picture4} alt="alt"/>
            <img className="carimg" src={picture5} alt="alt"/>
            <img className="carimg rotateImg" src={picture6} alt="alt"/>
            <img className="carimg" src={picture7} alt="alt"/>
            <img className="carimg" src={picture8} alt="alt"/>
            <img className="carimg" src={picture9} alt="alt"/>
            <img className="carimg" src={picture10} alt="alt"/>
            <img className="carimg" src={picture11} alt="alt"/>
        </Carousel>
    );
};

export default CaruselHandarbeit;