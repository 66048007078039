import React, {Component} from "react";
import backgroundv1 from "../Pictures/backgroundv1.png";
import '../index.css';
import LazyLoad from 'react-lazyload';

const sectionStyle = {
    backgroundImage: `url(${backgroundv1})`
};

class Home extends Component {
    render() {
        return (
            <LazyLoad>
                <div className="backcontent" style={sectionStyle}/>
            </LazyLoad>
        );
    }
}

export default Home;