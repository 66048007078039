import React, { Component } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Firmengeschichte from "./components/Firmengeschichte";
import Home from "./components/Home";
import SA from "./components/SA";
import { Route, BrowserRouter } from "react-router-dom";
import "./index.css";
import Fenster from "./components/Fenster";
import Handarbeit from "./components/Handarbeit";
import AlteHandwerks from "./components/AlteHandwerks";
import Moebeltischlerei from "./components/Moebeltischlerei";
import BauTischler from "./components/BauTischler";
import Impressum from "./components/Impressum";
import MoebelPlaner from "./components/MoebelPlaner";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <header>
          <Navbar />
        </header>
        <main>
          <Route exact path="/" component={Home} />
          <Route exact path="/handarbeit" component={Handarbeit} />
          <Route exact path="/handwerkstechnik" component={AlteHandwerks} />
          <Route exact path="/moebeltischlerei" component={Moebeltischlerei} />
          <Route exact path="/fenster" component={Fenster} />
          <Route exact path="/bautischler" component={BauTischler} />
          <Route path="/information" component={Firmengeschichte} />
          <Route path="/kontakt" component={Contact} />
          <Route path="/sa" component={SA} />
          <Route path="/planer" component={MoebelPlaner} />
          <Route path="/impressum" component={Impressum} />
        </main>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default App;
