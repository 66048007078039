import React from "react";
import '../index.css';
import CaruselMoebeltischlerei from "./PictureCarusel/CaruselMoebeltischlerei";


const Moebeltischlerei = () => {
    return (
        <div className="row maincontent">
            <div className="col s12 m8">
                <CaruselMoebeltischlerei/>
            </div>
            <div className="col s12 m4">
                <p className="flow-text fontSize">Persönlich - Individuell – Nachhaltig – Gemeinsam</p>
                <p className="flow-text fontSize">Folgende Arbeiten sind ein Auszug unseres breiten Spektrums im Bereich
                    der Möbeltischlerei:</p>
                <p className="flow-text fontSize">Alle Arten von Reparaturarbeiten</p>
                <p className="flow-text fontSize">Ergänzungsarbeiten von Möbeln, Möbelumbauten</p>
                <p className="flow-text fontSize">Neuanfertigungen von allen erforderlichen Einrichtungsgegenständen</p>
                <p className="flow-text fontSize">Brand- und Wasserschadensanierungen im Privatkundenbereich</p>
                <p className="flow-text fontSize">Spezielles Firmenkundenservice bei Reparaturen, Übersiedlungen,
                    Arbeitsplatzerweiterungen</p>
                <br/>
                <p className="flow-text fontSize">Meine Mitarbeiter und ich erfüllen jeden Tag „Spezialwünsche“ unserer
                    Kunden.</p>
                <p className="flow-text fontSize">Diese immer unter folgenden Kriterien:</p>
                <ul className="fontSize">
                    <li>o Von Mensch zu Mensch</li>
                    <li>o Auf Sie persönlich abgestimmt</li>
                    <li>o Soweit wie möglich Ressourcen schonend, muss alles neu gemacht werden? Ergänzungen,
                        Reparaturen, …
                    </li>
                    <li>o Mit Ihnen gemeinsam (Ideen, Farbkonzepte, teilweise Umsetzung)</li>
                </ul>
                <p className="flow-text fontSize">Ihr „Spezialwunsch“ ist unsere tägliche Herausforderung.</p>
            </div>
        </div>
    );
};

export default Moebeltischlerei;