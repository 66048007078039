import React from "react";
import picture1 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2370.jpg";
import picture2 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2377.jpg";
import picture3 from "../../Pictures/Fotos-190903/Fotos 190903-Web/190903__DSC2412.jpg";
import picture4 from "../../Pictures/Fotos-190827/Fotos 190827-Web/190827__DSC2204.jpg";
import picture5 from "../../Pictures/IMG-20190112-WA0000.jpg";
import picture6 from "../../Pictures/20190125_150025.jpg";
import picture7 from "../../Pictures/20190125_150032.jpg";
import picture8 from "../../Pictures/20181126_102242.jpg";
import picture10 from "../../Pictures/20181126_101401.jpg";
import picture11 from "../../Pictures/20181126_101411.jpg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import '../../index.css';
import LazyLoad from 'react-lazyload';

const CaruselAlteHandwerk = () => {

    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            infinite={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
            <LazyLoad>
            <img className="carimg" src={picture1} alt="alt"/>
            </LazyLoad>
            <img className="carimg" src={picture2} alt="alt"/>
            <img className="carimg" src={picture3} alt="alt"/>
            <img className="carimg" src={picture4} alt="alt"/>
            <img className="carimg" src={picture5} alt="alt"/>
            <img className="carimg" src={picture6} alt="alt"/>
            <img className="carimg" src={picture7} alt="alt"/>
            <img className="carimg" src={picture8} alt="alt"/>
            <img className="carimg" src={picture10} alt="alt"/>
            <img className="carimg" src={picture11} alt="alt"/>
        </Carousel>
    );
};

export default CaruselAlteHandwerk;