import React, { useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import M from "materialize-css";
import tischlerlogoNeu from "../Pictures/Tischler Logo Neu.PNG";
import meister from "../Pictures/Spiegel.png";

import "../index.css";

const Navbar = () => {
  let location = useLocation();

  useEffect(() => {
    M.Dropdown.init(document.querySelectorAll(".dropdown-trigger"), {
      constrainWidth: false,
    });

    M.Sidenav.init(document.querySelectorAll(".sidenav"));
  }, []);

  return (
    <>
      <nav className={`${location.pathname === "/" ? "transparent" : "orange"}`}>
        <div className="nav-wrapper">
          {location.pathname !== "/" && location.pathname !== "/sa" && (
            <>
              <img className="logo1" alt="Tischlerlogo" src={tischlerlogoNeu} width="90" height="75" />
              <img alt="Meisterlogo" src={meister} width="75" height="75" />
            </>
          )}
          <Link to="#" data-target="mobile-demo" className="sidenav-trigger">
            <i className="material-icons black-text">Menu</i>
          </Link>
          <ul className="nav-content right hide-on-med-and-down">
            <li>
              <NavLink className={`${location.pathname === "/" ? "black-text" : ""}`} to={"/"}>
                Startseite
              </NavLink>
            </li>
            <li>
              <Link
                to="#"
                className={`dropdown-trigger ${location.pathname === "/" ? "black-text" : ""}`}
                data-target="dropdown1"
              >
                Möbel
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className={`dropdown-trigger ${location.pathname === "/" ? "black-text" : ""}`}
                data-target="dropdown2"
              >
                Fenster/Bau
              </Link>
            </li>
            <li>
              <NavLink className={`${location.pathname === "/" ? "black-text" : ""}`} to={"/handwerkstechnik"}>
                Alte Handswerktechnik
              </NavLink>
            </li>
            <li>
              <NavLink className={`${location.pathname === "/" ? "black-text" : ""}`} to={"/information"}>
                Firmengeschichte
              </NavLink>
            </li>
            <li>
              <NavLink className={`${location.pathname === "/" ? "black-text" : ""}`} to={"/sa"}>
                Sachverständiger
              </NavLink>
            </li>
            <li>
              <NavLink className={`${location.pathname === "/" ? "black-text" : ""}`} to={"/planer"}>
                Möbelplaner
              </NavLink>
            </li>
            <li>
              <NavLink className={`${location.pathname === "/" ? "black-text" : ""}`} to={"/kontakt"}>
                Kontakt
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <ul id="dropdown1" className="dropdown-content">
        <li>
          <NavLink className="black-text" to={"/handarbeit"}>
            Handarbeit
          </NavLink>
        </li>
        <li>
          <NavLink className="black-text" to={"/moebeltischlerei"}>
            Möbeltischlerei
          </NavLink>
        </li>
      </ul>
      <ul id="dropdown2" className="dropdown-content">
        <li>
          <NavLink className="black-text" to={"/fenster"}>
            Fenster
          </NavLink>
        </li>
        <li>
          <NavLink className="black-text" to={"/bautischler"}>
            Bautischlerarbeiten
          </NavLink>
        </li>
      </ul>
      <ul className="sidenav" id="mobile-demo">
        <li>
          <NavLink to={"/"}>Home</NavLink>
        </li>
        <li>
          <NavLink to={"/handarbeit"}>Handarbeit</NavLink>
        </li>
        <li>
          <NavLink to={"/moebeltischlerei"}>Möbeltischlerei</NavLink>
        </li>
        <li>
          <NavLink to={"/fenster"}>Fenster</NavLink>
        </li>
        <li>
          <NavLink to={"/bautischler"}>Bautischlerarbeiten</NavLink>
        </li>
        <li>
          <NavLink to={"/handwerkstechnik"}>Alte Handswerktechnik</NavLink>
        </li>
        <li>
          <NavLink to={"/information"}>Firmengeschichte</NavLink>
        </li>
        <li>
          <NavLink to={"/sa"}>Sachverständiger</NavLink>
        </li>
        <li>
          <NavLink to={"/planer"}>Möbelplaner</NavLink>
        </li>
        <li>
          <NavLink to={"/kontakt"}>Kontakt</NavLink>
        </li>
      </ul>
    </>
  );
};

export default Navbar;
