import React from "react";
import "../index.css";
import { NavLink, useLocation } from "react-router-dom";

const Footer = () => {
  let location = useLocation();

  return (
    <footer className={`page-footer ${location.pathname === "/" ? "transparent" : "orange"}`}>
      <div className="footer-copyright">
        <div className={`container ${location.pathname === "/" ? "black-text" : ""}`}>
          © 2020-2023 Copyright
          <NavLink className={`right ${location.pathname === "/" ? "black-text" : "white-text"}`} to="/impressum">
            Impressum
          </NavLink>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
