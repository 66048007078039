import React from "react";
import picture1 from "../Pictures/IMG_8730.jpg";
import picture2 from "../Pictures/SA/Eichenboden - Splinthölzer.JPG";
import svlogo from "../Pictures/svlogo.png";
import styled from "styled-components";
import '../index.css';
import '../components/sachver.css';
import LazyLoad from 'react-lazyload';

const PNoMargin = styled.p`
    margin-top: 0.4em;
    margin-bottom: 0px;
`;

const SA = () => {

    return (
        <div className="maincontent row">
            <div className="col s12 m5 center sapicturebox">
                <LazyLoad>
                <img alt="Ludwig Weichinger-Hieden" src={picture1} className="sachpic"/>
                </LazyLoad>
                <p className={"fontSize"}><a className="mailtoui"
                                             href="mailto:ludwig.weichinger-hieden@gerichts-sv.at">ludwig.weichinger-hieden@gerichts-sv.at</a>
                </p>
            </div>
            <div className="col s12 m7">

                <h2 className="heading">Sachverständiger</h2>
                <img alt={"SV-Logo"} className="oval" src={svlogo} width="15%"/>

                <div className="section flow-text fontSize">
                    <p>2008: Gerichtssachverständigenprüfung über Teile der Fachgruppe 39, Holzbearbeitung</p>
                    <div className="divider"></div>
                    <p>2011: Erweiterungsprüfung bei Gericht für die Fachgruppe 39 – Holzbearbeitung</p>
                    <div className="divider"></div>
                    <p>2019: Bauforensik, Anforderungen und Einsatzgebiete. Bildgebende Darstellung von für das
                        menschliche Auge nicht sichtbaren Details unter Zuhilfenahme von IR und UV Lampen
                        mittels Spezialkameras.</p>
                </div>
                <div className="flow-text fontSize">
                    <p>Als <b>allgemein beeideter und gerichtlich zertifizierter
                        Sachverständiger</b> erstatte ich Gerichten und Privatpersonen Beweissicherungen, Befunde
                        und Gutachten.
                        Gutachten erstelle ich zu folgenden Fachgebieten, welche auch in der online abrufbaren Liste
                        der
                        allgemein beeideten und gerichtlich zertifizierten Sachverständigen Österreichs, geführt vom
                        BM für Justiz aufgelistet werden:
                    </p>
                    <p>39.10. Bautischlerarbeiten</p>
                    <p>30.11. Möbeltischlerarbeiten, Kunsttischlerarbeiten, Möbelerzeugnisse und Möbelhandel</p>
                    <p>30.25. Parkette, andere Holzböden</p>
                    <p>30.26. Furniere, Faserplatten</p>
                    <p>30.40. sonstige Holzverarbeitung, nur für Holzterrassen</p>
                    <br/>
                </div>
            </div>

            <div className="col s12 offset-m5 m7 flow-text fontSize">
                <PNoMargin>Als Tischlermeister unterstütze ich die Gerichte mit meinem
                    Fachwissen zu den unterschiedlichsten Fragen wie z.B.:</PNoMargin>
                <PNoMargin>Kastenfenster – fachgerechte Sanierungen?</PNoMargin>
                <PNoMargin>Isolierglasfenster und Türen – normgerechter Einbau?</PNoMargin>
                <PNoMargin>Ablösesummen von Möbeleinrichtungen?</PNoMargin>
                <PNoMargin>Ist die Ausführung und Montage der gelieferten Möbel laut Anbot?</PNoMargin>
                <PNoMargin>Fugenbildungen bei Parkettböden – normal?</PNoMargin>
                <PNoMargin>Holzauswahl und Verlegung von Fertigparkettböden nach Norm?</PNoMargin>
                <PNoMargin>Darf sich eine furnierte Spanplatte durchbiegen, wenn ja,
                    wieviel?</PNoMargin>
                <PNoMargin>Aufbau von Holzterrassen – fachgerecht?</PNoMargin>
                <PNoMargin>Montage der Deckdielen fachgerecht?</PNoMargin>
                <br/>
                <PNoMargin>Diese, und noch viel mehr Themen interessieren natürlich auch
                    Privatpersonen, welche ich aus fachlicher Sicht berate du unterstütze.</PNoMargin>
                <PNoMargin>Istzustand feststellen = Beweissicherung/Befund</PNoMargin>
                <PNoMargin>Schlüsse aus dem Befund ziehen = Gutachten</PNoMargin>
            </div>


            <img className="col s12 m5" src={picture2} alt="Eichenboden"/>
            <div className="col s12 m7 flow-text fontSize">
                <PNoMargin>Ein ganz spezielles Thema in meiner Tätigkeit als Sachverständiger
                    umfasst die <b>Bauforensische Fotographie!</b></PNoMargin>
                <PNoMargin>Bei diesen forensischen Untersuchungen werden mittels eigener
                    Lampen vom UV Bereich bis zum IR Bereich Objekte, Platten, Details, Fenster, …
                    beleuchtet, um für das Auge nicht sichtbare Tatsachen aufzudecken.</PNoMargin>
                <PNoMargin>Mit einer eigens für diesen Zweck umgebauten Kamera kann ich dann,
                    mit den passenden Filtervorsätzen, spezielle Bilder dieser Details dann machen und vorher
                    optisch nicht sichtbare Sachen nun genau nachweisbar darstellen. </PNoMargin>
                <br/>
                <PNoMargin>Diese Nachweise können sein:</PNoMargin>
                <PNoMargin>Lackierte Flächen ohne „UV“ Schutz</PNoMargin>
                <PNoMargin>Kondensatspuren an Fenstern an der Innenseite</PNoMargin>
                <PNoMargin>Weggewischte Schimmelsporen bei Wärmebrücken</PNoMargin>
                <PNoMargin>Holzartenbestimmungen</PNoMargin>
                <PNoMargin>Original und Fälschung bei Fertigparkettböden, spezieller Plattenwerkstoffe, …</PNoMargin>
                <PNoMargin>Leimdruchschlag beim Furnieren an Plattenoberflächen nach dem Lackieren</PNoMargin>
                <br/>
                <PNoMargin>Dieses Gebiet ist fast unendlich ausbaubar!</PNoMargin>
                <br/>
                <PNoMargin>Die Kosten meiner Gutachten richten sich immer nach dem Aufwand
                    und werden nach Stunden abgerechnet.</PNoMargin>
            </div>

        </div>
    );
};
export default SA;